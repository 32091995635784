@import "./_theme_colors";
@import "./_theme_font";
@import "./_theme_custom";
@import "./_dates";
@import "./_tables";
//
@import "./header";
//
@import "_loading_spinner";

@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

//
.-bottom-30 {
  bottom: -6rem;
}
