.simple-table{
  th {
    @apply font-semibold;
  }
  // Set border-radius on the top-left and bottom-left of the first table data on the table row
  td:first-child,
  th:first-child {
    @apply rounded-l-lg;
  }

  // Set border-radius on the top-right and bottom-right of the last table data on the table row
  td:last-child,
  th:last-child {
    @apply rounded-r-lg;
  }
}

.simple-table-container {
  overflow-x: auto;
  display: block;
  width: 100%;
}
